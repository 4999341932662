import dayjs from '@troon/dayjs';
// eslint-disable-next-line import/no-unresolved
import { createAsync, useLocation, useParams } from '@solidjs/router';
import {
	BreadcrumbItem,
	Breadcrumbs,
	Heading,
	Picture,
	Tag,
	Container,
	Page,
	Section,
	HorizontalRule,
} from '@troon/ui';
import { Show, Suspense } from 'solid-js';
import { Meta, Title } from '@solidjs/meta';
import { getConfigValue } from '../../../modules/config';
import { createContentfulRequest } from '../../content/[model]/_client';
import { RichText } from '../../../components/rich-text';
import { GridThird } from '../../../components/layouts/grid';
import { ContactForm } from '../_components/contact-form';

export default function PressReleasePage() {
	const loc = useLocation();
	const params = useParams<{ 'industry-insight': string }>();
	const data = createAsync(async () => getArticle(params['industry-insight']), {
		deferStream: true,
	});

	return (
		<>
			<Suspense>
				<Show when={data()?.fields}>
					{(data) => (
						<>
							<Title>{data().title} | Industry Insights | Management Services | Troon</Title>
							<Meta name="description" content={data().title} />
							<Meta name="og:title" content={`${data().title} | Troon`} />
							<Meta
								name="og:url"
								content={`https://${getConfigValue('HOST')}/management-services/industry-insights/${data()?.slug}`}
							/>
							<Meta name="og:type" content="article" />
							<Meta name="article:published_time" content={dayjs(data()?.publishDate).toISOString()} />
							<Meta name="og:image" content={`https://${data()?.heroImage?.fields.file?.url}`} />
						</>
					)}
				</Show>
			</Suspense>
			<Container class="pt-4">
				<Suspense>
					<Breadcrumbs>
						<BreadcrumbItem href="/management-services">Management Services</BreadcrumbItem>
						<BreadcrumbItem href="/management-services/industry-insights">Industry Insights</BreadcrumbItem>
						<Show when={data()?.fields}>
							{(data) => <BreadcrumbItem href={loc.pathname}>{data().title}</BreadcrumbItem>}
						</Show>
					</Breadcrumbs>
				</Suspense>
			</Container>

			<Page>
				<Container size="small">
					<Section>
						<Tag class="text-sm">Industry Insight</Tag>
						<Heading as="h1">
							<Suspense fallback="…">
								<Show when={data()?.fields}>{(data) => <>{data().title}</>}</Show>
							</Suspense>
						</Heading>

						<Suspense>
							<Show when={data()?.fields.heroImage?.fields}>
								{(image) => (
									<Picture
										preload
										src={`https://${image().file?.url}`}
										width={1024}
										height={576}
										sizes="(min-width: 1440px) 50vw, 95vw"
										class="w-full sm:rounded"
										alt={image().description ?? image().title ?? ''}
										crop="face"
									/>
								)}
							</Show>
						</Suspense>
					</Section>

					<Suspense>
						<Show when={data()?.fields.content}>{(content) => <RichText document={content()} />}</Show>
					</Suspense>
				</Container>

				<Container>
					<HorizontalRule />
				</Container>

				<Container size="small">
					<GridThird>
						<div class="rounded border border-neutral-400 bg-neutral-100 p-6">
							<ContactForm />
						</div>
					</GridThird>
				</Container>
			</Page>
		</>
	);
}

const getArticle = createContentfulRequest('articles', { 'fields.articleType': 'Industry Insights' });
